import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import ErrorCodeText from "./ErrorCodeText"
import Text from "./Text"

const NotFound = () => {
  return (
    <Wrapper>
      <ErrorCodeText>404</ErrorCodeText>
      <Text>
        Bohužiaľ sme nenašli čo hľadáte, skúste to znova alebo sa vráťte na
        hlavnú stránku.
      </Text>
    </Wrapper>
  )
}

export default NotFound
