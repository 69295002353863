import styled from "styled-components"

const Text = styled.div`
  margin: 30px auto 0px;
  width: 275px;
  text-align: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    width: 315px;
  }
`

export default Text
