import styled from "styled-components"

const Wrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 60px;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    margin-top: 140px;
  }
`

export default Wrapper
