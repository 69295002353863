import styled from "styled-components"

const ErrorCodeText = styled.div`
  text-align: center;
  font-size: 120px;
  font-weight: 700;
  color: ${props => props.theme.colors.primary.green};
`

export default ErrorCodeText
